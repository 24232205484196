// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $medium; // Navigation Link Color

@mixin navButton {
  // Default Link Styles
  padding: 1em rem($baseGap);
  text-decoration: none;
  color: #202020;
  text-transform: lowercase;
}
%buttonHover {
  // Link Hover
  color: $light;
  background-color: $primary;
  @include breakpoint($break) {
    color: $alert;
    background-color: transparent;
  }
}
%buttonActive {
  // Link Active
  color: $light;
  background-color: $alert;
  @include breakpoint($break) {
    color: $alert;
    background-color: transparent;
    font-weight: bold;
  }
}
%prevIcon {
  // Icon to previus Submenu
  font-family: $iconFont;
  content: map-get($iconMap, angle-right);
  transform: rotateY(180deg);

  // @include breakpoint($break) {
  // 	transform:rotate(90deg);
  // }
}
%nextIcon {
  // Icon to next Submenu
  font-family: $iconFont;
  content: map-get($iconMap, angle-right);

  @include breakpoint($break) {
    transform: rotate(90deg);
  }
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
  transition: .3s ease-in-out;
  background-color: $navBg;
  z-index: 1001;

  nav {
    .toolbarAddress{
      text-align: left;
      font-size: rem(16px);
      padding: rem(18px);
      border-bottom: 1px solid $border;
      span{
        display: block;
      }
    }
    @include ul {
      &.navi {
        display: flex;
        flex-direction: column;
        padding-bottom: rem(90px);
        @include breakpoint(giant){
          flex-direction: row;
        }
        
        li {
          a,span{
            transition: .3s ease-in-out;
          }
          @include navButtons {
            @include navButton;
          }
          @include hasSub {
            @include sub {
              transition: .3s ease-in-out;
              background: $navBg;
              padding-bottom: rem(90px);

              &:before {
                padding: 1em rem($baseGap);
                background-color: darken($navBg, 6%);
              }
            }
          }
          @include navBack {
          }
          &.shopNow{
            order: 1;
            transition: .3s ease-in-out;
            @include breakpoint(giant){
              order: 7;
            }
          }
          &.home{
            order: 2;
            @include breakpoint(giant){
              order: 1;
            }
          }
          &.about{
            order: 3;
            @include breakpoint(giant){
              order: 2;
            }
          }
          &.learningTheCrafts{
            order: 4;
            @include breakpoint(giant){
              order: 3;
            }
          }
          &.products{
            order: 5;
            @include breakpoint(giant){
              order: 4;
            }
          }
          &.gallery{
            order: 6;
            @include breakpoint(giant){
              order: 5;
            }
          }
          &.contactUs{
            order: 7;
            @include breakpoint(giant){
              order: 6;
            }
          }
        }
      }
    }
  }
}
@include breakpoint($break, max) {
  #navigation {
    .branding {
      display: none;
    }
  }
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
  @include desktopVersionFunctionality;
  .nav-wrapper {
    max-width: rem(1400px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  #navigation {
    background: transparent;
    padding: rem(60px) 0 0 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    .branding {
      max-width: rem(300px);
      transition: all 0.3s ease-in-out;
    }
    nav {
      .toolbarAddress{
        text-align: right;
        padding: rem(18px);
        border-bottom: none;
        @include breakpoint(giant) {
          padding: 0 0 rem(18px) 0;
        }
        span{
          display: inline;
        }
      }
      ul {
        &.navi {
          justify-content: center;
          padding-bottom: 0;
          li {
            &.hasSub {
              .sub {
                z-index: 10;
                max-width: rem(250px);
                padding-bottom: 0;
              }
            }
            &.navBack {
            }
            &.shopNow {
              background: $alert;
              border: none;
              color: $light;
              cursor: pointer;
              display: inline-block;
              font-size: rem($baseFontSize);
              line-height: rem($baseLineHeight);
              padding: 1rem;
              text-align: center;
              text-decoration: none;
              font-family: $mainFont;
              a{
                padding: 0;
                color: $light;
              }

              &:hover,
              &:active,
              &:focus {
                background: $dark;
                color: $light;
              }

              *[class^="icon"] {
                margin-left: 0.5rem;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
    &.in,
    body:not(.index) & {
      background: $medium;
      padding: rem(20px) 0;
      .branding {
        max-width: rem(150px);
        height: auto;
      }
    }
  }
}

// Quickbar Styles
////
#quickbar {
  background-color: $barBg;
  color: $barColor;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: rem($baseGap);
  z-index: 1000;

  @include breakpoint($break) {
    display: none;
  }
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
  display: flex;
  justify-content: center;
  padding: $baseGap 0 rem(80px);
  li {
    a {
      color: $secondary;
      &:hover,
      &.active {
        color: $alert;
      }
    }
  }
  @include breakpoint(small) {
    padding: $baseGap 0;
  }
}
