// Form styles
form.default {
	width: 100%;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $primary;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $primary;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $primary !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $primary;
	}

	fieldset {
		border: none;
		margin: 0 0 1.5rem 0;
		@include breakpoint($break){
			margin: 0 0 3.5rem 0;
		}
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}

		&[for="Datenschutz"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 1.5rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		// float: right;
		margin: 0 rem(15px) 0.5rem 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
  }
  .captcha-container{
    margin-bottom: 1rem;
  }
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
label{
	&.hide{
		display: none !important;
	}
}
.captcha-container{
	transform: scale(0.8);
    transform-origin: 0 0;
}