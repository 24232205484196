/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Muli", sans-serif;

$displayFont: "Muli", sans-serif;

$iconFont: "icomoon", sans-serif;

// Project colors

$primary: #393939;
$secondary: #444444;
$light: #fff;
$medium: #e8f1f6;
$dark: #89c3e2;
$border: #e5e5e5;
$alert: #a7291d;

$baseGap: 18px;

$rowMaxWidth: 2000px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	shop: ""\f07a"", 
	phone: ""\e900"", 
	envelope: ""\e901"", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (4, 5, 6, 7),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (4, 6),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
	mega: (
		width: em(2450px, $bpContext), 
		cols: (auto),
	),
	mega2: (
		width: em(1665px, $bpContext), 
		cols: (auto),
	),
);

