// Mobile Mixins
@mixin before {
	content: '';
	position: absolute;
	display: flex!important;
	justify-content: center;
	align-items: center;
	top: 0;
	left: -40px;
	height: 50px;
	width: 100px;
	font-size: 30px;
	@include breakpoint($break){
		width: 50px;
	}
}

@mixin mobileBefore {
	position: relative;
	left: 0;	
	z-index: 1;
}

@mixin mobileFixedContacts {
	display: flex;
	// justify-content: space-between;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 200px;
	z-index: 9996;
	height: 50px;
}

@mixin mobileLi {
	position: relative;	
	width: 82px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right:$baseBorder;
}

@mixin mobileInner {
	position: fixed;
	width: calc(100% - 6px);
	top: 7rem;
	left: 3px;
	text-align: center;
	transition: 300ms all;
	transform: translate(0, -50px);
	border-radius: 5px;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
}

@mixin mobileNohoverInner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
	visibility: visible;
	padding: 0;
	transform: translate(-50%, -50%);
}

@mixin coverLink {
	position: relative;
	display: block;
	height: 100%;
}

//////////////////////////////////////////////////////////////
//Desktop Mixins

@mixin desktopFixedContacts {
	position: fixed;
	display: block;
	right: 0;
	z-index: 999;
}

@mixin desktopLi {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate(100%, 0);
	right: 0;
	transition: 300ms all;
	min-height: 60px;
}

@mixin nthOfType {
	@for $i from 1 through 10 {
		&:nth-of-type(#{$i}) {
			top: calc(60px*#{$i} + 5px*#{$i});
			z-index: calc(100 - #{$i});

			body.internetExplorer & {
				z-index: -$i;
			}
		}
	}
}

@mixin desktopBefore {
	content: '';
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: -60px;
	top: 0;
	height: 100%;
	max-height: 60px;
	width: 60px;
}

@mixin desktopNoHover {
	position: absolute;
	transform: none;
	right: 0;
	width: 60px;
	padding: 0;
}