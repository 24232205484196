
// HEADER
//////////////////////////////
.lazyBg{
	background-size: auto;
	&.lazyLoaded {
        background-size:40px;
        background-position:center center;

        &.isOnScreen{
            background-size:cover !important;
        }
    }
}
#header {
		overflow: hidden;
		position:relative;
		@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);
		// background-size: cover;
		.headerDistance{
			padding:0 $baseGap !important;
			.button-container{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				a:first-of-type{
				margin-bottom: 1rem;
				}
				.btn{
					padding: 1rem 1.5rem;
					@include breakpoint(medium){
						padding: 2rem;
					}
				}
			}
		}

		body:not(.internetExplorer):not(.edge):not(.ipad):not(.iphone) &{
			background-attachment: fixed;
		}
		body:not(.iPad):not(.iPhone) &{
			.normal{
				width:100%;
			}
			.noSupport{
				display:none;
			}

		}
		body.iPad &{
			min-height:100vh;
			.normal{
				display:none;
			}
			.noSupport{
				display:block;
				width:100%;
				height:100%;
				color:$light;
				p{
					span{
						font-size:rem(170px);
					}
				}
			}
		}
		body.privacyPolicy & {
			margin-bottom: 4rem;
		}

		@include breakpoint($break){
			body:not(.index) &{
				padding-top:rem(185px);
			}
		}
		.row{
			padding-left:0;
			padding-right:0;
			p{
				padding:0 $baseGap;
			}

		}

		.overlay{
			position:relative;
			width:100%;
			padding-bottom:5rem;
			&:before{
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background:$light;
				opacity:.7;
				z-index:2;
			}
			.row{
				z-index:5;
				p{
					margin-bottom:3rem;
				}
			}
			@include breakpoint($break){
				padding-bottom:18rem;
			}
		}
		.masks{
		  position: relative;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 220px;
		  z-index: 10;
		  overflow:hidden;
		  p{
		  	display:block;
		  	text-align: center;
		  	font-size: rem(25px);
		  	line-height: rem(32px);
		  	background:rgba(255,255,255,.7);
		  	padding:5rem $baseGap 0 $baseGap;
		  	text-transform: lowercase;
		  	color:$primary;
		  	@include breakpoint($break){
		  		padding:19rem $baseGap 0 $baseGap;
		  	}
		  	 @include breakpoint(small){
		    	 font-size: rem(35px);
		    	 line-height: rem(40px);
		    }
		    @include breakpoint(giant){
		    	  font-size: rem(68px);
		    	  line-height: 4.17rem;
		    }
		  }
		  @include breakpoint(small){
		  		height: 300px;
		  }
		  @include breakpoint($break){
		  		height: 800px;
		  	}
		}
		svg{
		  width: 100%;
		  height: inherit;
		  text{
		  text-anchor: middle;
		  }
		  #alpha2{
		    fill: $light;
		    //animation: alpha 10s ease infinite;
		  }
		  .title{
		    font-weight: 800;
		    font-size: rem(60px);
		    @include breakpoint(small){
		    	 font-size: rem(100px);
		    }
		    @include breakpoint(giant){
		    	 font-size: rem(270px);
		    }
		  }
		  .subtitle{
		    font-size: rem(25px);
		    font-weight: 300;
		    text-transform: uppercase;

		    @include breakpoint(small){
		    	 font-size: rem(40px);
		    }
		    @include breakpoint(giant){
		    	  font-size: rem(80px);
		    }
		  }
		  #base2{
		    fill: rgba(255,255,255,.7);
		    // add the mask.
		    // aplicar máscara.
		    mask: url(#mask2);
		  }
		}
		.branding {
			display: inline-block;
			max-width: 200px;
			width: 100%;
			height: auto;
			position: relative;
			z-index: 1;
			background:none;
		}
		.brand{
			width:100%;
			background:$light;
			padding:70px $baseGap $baseGap $baseGap;
			text-align: center;
			border-bottom:$baseBorder;
			@include breakpoint($break) {
				display: none;
			}
		}
}

// MAIN CONTENT
//////////////////////////////

main {
	background:$light;
	position:relative;
	z-index:5;
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.teaser{
		display:flex;
		margin-bottom:0;
		text-align: center;
		flex-wrap:wrap;
		li{
			flex:1 1 100%;
			background:$primary;

			a{
				display:block;
				text-transform: lowercase;
				padding:rem(80px) $baseGap;
				color:$light;
				position:relative;
				overflow:hidden;
				span{
					font-size:rem(30px);
					line-height:rem(34px);
					span{
						font-weight: 900;
						display:block;
						font-size:rem(50px);
						line-height:rem(54px);
					}
					&.hover{
						background:$alert;
						color:$light;
						padding:1.5rem;
						width:100%;
						display:block;
						position:absolute;
						bottom:0;
						left:0;
						font-size:1rem;
						font-weight: bold;
						transform:translateY(100%);
						transition: all .3s ease-in-out;
					}
				}
				&:hover{
					span{
						&.hover{
							transform:translateY(0);
						}
					}
				}
				@include breakpoint(medium){
					padding:rem(160px) $baseGap;
				}
			}
			&:nth-child(1){
				a{
					@include responsiveImage('teaser/first.jpg', (tiny), true);
					// background-size: cover;
				}
			}
			&:nth-child(2){
					a{
					@include responsiveImage('teaser/second.jpg', (tiny), true);
					// background-size: cover;
				}
			}
			&:nth-child(3){
				a{
					@include responsiveImage('teaser/third.jpg', (tiny), true);
					// background-size: cover;
				}
			}
			&:nth-child(4){
				a{
					@include responsiveImage('teaser/fourth.jpg', (tiny), true);
					// background-size: cover;
				}
			}
			// &:nth-child(even){
			// 	background:$dark;
			// }
			@include breakpoint(tiny){
				flex: 1 1 50%;
			}
			@include breakpoint(large){
				flex: 1 1 25%;
			}
		}
	}
	.swiperCmsIndex{
		padding-bottom: rem(35px) !important;
		.swiper-button-prev,.swiper-button-next{
			transition: .3s ease-in-out;
		}
		.swiper-button-prev{
			left: 60%;
			@include breakpoint(small){
				left: 55%;
			}
			@include breakpoint(large){
				left: 60%;
			}
			@include breakpoint(huge){
				left: 58%;
			}
			@include breakpoint(full){
				left: 55%;
			}
		}
		.swiper-button-next{
			right: 60%;
			@include breakpoint(small){
				right: 55%;
			}
			@include breakpoint(large){
				right: 60%;
			}
			@include breakpoint(huge){
				right: 58%;
			}
			@include breakpoint(full){
				right: 55%;
			}
		}
	}
	.ball{
		position:relative;
		&:before{
			@include breakpoint(small){
				content:'';
				position: absolute;
				bottom:rem(-149px);
				right:0;
				background:url('../images/layout/yarnBall.jpg') no-repeat center center /cover;
				width:rem(254px);
				height:rem(178px);
			}
			@include breakpoint(full){
				width:rem(509px);
				bottom:rem(-297px);
				height:rem(356px);
			}
		}
	}
	.heart{
		position:relative;
		@include breakpoint(mega){
			&:before{
				content:'';
				position: absolute;
				bottom:rem(-127px);
				left:-3rem;
				background:url('../images/layout/heart.jpg') no-repeat center center /cover;
				width:rem(330px);
				height:rem(322px);
				z-index:3;
			}
		}
	}

	.headline{
		blockquote{
			text-transform: lowercase;
			 font-size: 28px;
			 @include breakpoint(large){
			 	margin-bottom:2.5rem;
			 }
			span{
				display:block;
			    color: transparent;
			    font-size: 50px;
			    line-height: rem(65px);
				font-weight: 900;
			}
			@include breakpoint(small){
				font-size: 38px;
				 margin-bottom:5rem;
				span{
					font-size: 100px;
			    	line-height: rem(150px);
				}
			}
			@include breakpoint(large){
				font-size: 68px;
				span{
					font-size: 177px;
			    	line-height: rem(220px);
				}
			}
		}
		body.internetExplorer &, body.edge &{
			&.shop{
				blockquote{
					span{
						color:#a20329;
						background:none;
					}
				}
			}
			&.instagram{
				blockquote{
					span{
						color:#617182;
						background:none;
					}
				}
			}
		}

		@supports ( -webkit-text-stroke:thin ) {
			&.shop{
				blockquote{
					span{
						 -webkit-background-clip: text;
					    -moz-background-clip: text;
					    background-clip: text;
					    @include responsiveImage('layout/bgFavourites.jpg', (tiny), true);
						background-size: cover;
					}
				}
			}
			&.instagram{
				blockquote{
					span{
						 -webkit-background-clip: text;
					    -moz-background-clip: text;
					    background-clip: text;
					     @include responsiveImage('layout/bgInstagram.jpg', (tiny), true);
						background-size: cover;
					}
				}
			}
		}
	}
	.feedContainer{
		width:100%;
		body:not(.internetExplorer) &{
			display:flex;
			flex-wrap: wrap;
		}
		.feed{
			text-align: center;
			body:not(.internetExplorer) &{
				flex:1 1 100%;
				padding:$baseGap;
			}
			figure{
				height:rem(200px);
				overflow:hidden;
				position:relative;
				border:$baseBorder;
				margin:1.5%;
				img{
					position:absolute;
					top:50%;
					left:50%;
					width:400px;
					// min-height:100%;
					// min-width:100%;
					height:auto;
					transform:translate(-50%, -50%);
					margin-bottom:0;
					max-width:none;
				}
				@include breakpoint(small){
					height:rem(340px);
					img{
						min-height:100%;
						width:auto;
						min-width:100%;
					}
				}
			}
			@include breakpoint(tiny){
				body:not(.internetExplorer) &{
					flex:1 1 50%;
				}
				float:left;
				width:50%;
			}
			@include breakpoint(full){
				body:not(.internetExplorer) &{
					flex:1 1 20%;
				}
				width:25%;
			}
		}
	}
	

	.brands{
		display:flex;
		flex-wrap: wrap;
		body:not(.internetExplorer) &{
			justify-content: left;
		}

		li{
			display:flex;
			justify-content: center;
			padding:$baseGap;
			text-align: center;
			border:$baseBorder;
			float:left;
			@include breakpoint(tiny){
				width:50%;
				body:not(.internetExplorer) &{
					flex:0 1 50%;
				}
			}
			a{
				&:hover{
					opacity:.7;
				}
			}
			&:nth-child(even){
				background:darken($light, 5%);
			}
			@include breakpoint(small){
				body:not(.internetExplorer) &{
					flex:0 1 33.33%;
				}
				width:33.33%;
			}
			@include breakpoint(medium){
				body:not(.internetExplorer) &{
					flex:0 1 25%;
				}
				width:25%;
			}
			@include breakpoint(large){
				body:not(.internetExplorer) &{
					flex:0 1 20%;
				}
				width:20%;
			}
			img{
				margin-bottom:0;
			}
			.name, .link{
				padding:3rem 0;
				display:flex;
				align-items: center;
			}
		}
	}
	.leaf{
		position:relative;
		@include breakpoint(large){
			&:before{
				content:'';
				background:url('../images/layout/leaf.jpg') no-repeat center center;
				width:rem(363px);
				height:rem(341px);
				position:absolute;
				top:rem(-178px);
				left:0;
			}
		}
	}
	.needles{
		position:relative;
		&:after{
			@include breakpoint(large){
				content:'';
				background:url('../images/layout/needles.jpg') no-repeat center center /contain;
				position:absolute;
				width:rem(331px);
				height:rem(222px);
				bottom:rem(-146px);
				right:0;
			}
			@include breakpoint(full){
				width:rem(663px);
				height:rem(445px);
				bottom:rem(-292px);
			}
		}
	}
}
#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
  }
  
}
	.map{
		min-height:rem(600px);
		float:left;
	}


// FOOTER
//////////////////////////////

#footer {
	background:$light;
	position:relative;
	z-index:4;
	overflow: hidden;
	.row{
		z-index:5;
	}
	.mobileDistance{
		margin:$baseGap 0;
		@include breakpoint(full){
			margin:0;
		}
		h4{
			strong{
				color: darken($dark , 25%) !important;
			}
		}
	}

	.socials{
		
		display:inline-flex;
		align-items: center;
		li{
			display:inline-block;
			padding:0 .5rem;

			img{
				margin-bottom: 0;
			}
			a{
				&:hover{
					opacity:.7;
				}
			}
		}
	}

	.bg{
		@include responsiveImage('layout/bgBanner.jpg', (tiny), true);
		background-size: auto 100%;
		&.isOnScreen{
            background-size: auto 100% !important;
        }
	}

	.form{
		overflow: hidden;
		position:relative;
		@include responsiveImage('layout/bgFooter.jpg', (tiny, medium), true);
		// background-size: cover;
	
		body:not(.internetExplorer) &, body:not(.edge) &, body:not(.ipad) &, body:not(.iphone) &{
			// background-attachment: fixed
		}
		
		.overlay{
			position:relative;
			&:before{
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background:$light;
				opacity:.7;
				z-index:2;
			}
			.row{
				z-index:5
			}
		}
		.masks{
		  position: relative;
		  top: 0;
		  left: 0;
		  width: 100%;
		 
		  height: 170px;
		  z-index: 10;
		  overflow:hidden;
		  .ipadOnly{
		  	p{
		  		padding-top:2rem;
		  		color:$alert;
		  		font-weight: 800;
			    font-size: rem(60px);
			    @include breakpoint(small){
			    	 font-size: rem(100px);
			    }
			    @include breakpoint(giant){
			    	 font-size: rem(270px);
			    }
		  	}
		  	body.ipad.index &, body.iphone.index &{
		  		display:none;
		  	}
		  	body:not(.ipad):not(.iphone) &{
		  			display:none;
		  	}
		  }
		  p{
		  	position:relative;
		  	z-index:5;
		  	display:block;
		  	text-align: center;
		  	font-size: rem(25px);
		  	line-height: rem(35px);
		  	background:rgba(255,255,255,.7);
		  	padding: rem(60px) $baseGap 0 $baseGap;
		  	text-transform: lowercase;
		  	color:$primary;
		  @include breakpoint($break){
		  		padding:8rem $baseGap 0 $baseGap;
		  	}
		  	 @include breakpoint(small){
		    	 font-size: rem(35px);
		    	 line-height: 2.20rem;
		    }
		    @include breakpoint(medium){
		    	 line-height: 2.22rem;
		    }
			@include breakpoint(large){
				padding:5rem $baseGap 0 $baseGap;
			}
		    @include breakpoint(giant){
		    	  font-size: rem(68px);
		    	  body:not(.ipad):not(.iphone) &{
			    	  line-height: 4.15rem;
			    	}
		    } 
			 @include breakpoint(huge){
			 	body:not(.ipad):not(.iphone) &{
		    	  line-height: 4.17rem;
		    	}
		    }
		    @include breakpoint(full){
		    	  font-size: rem(68px);
		    }
		  }
		  @include breakpoint(small){
		  		height: 230px;
		  }
		  @include breakpoint(large){
			height: 300px;
		  }
		  @include breakpoint($break){
		  		height: 600px;
		  	}
		}
		svg{
		  width: 100%;
		  height: inherit;
			
		  text{
		  text-anchor: middle;
		  }
		  #alpha{
		    fill: $light;
		    //animation: alpha 10s ease infinite;
		  }
		  .title{
		    font-weight: 800;
		    font-size: rem(60px);
		    @include breakpoint(small){
		    	 font-size: rem(100px);
		    }
		    @include breakpoint(giant){
		    	 font-size: rem(270px);
		    }
		  }
		  .subtitle{
		    font-size: rem(80px);
		    font-weight: 300;
		    text-transform: uppercase;
		  }
		  #base{
		    fill: rgba(255,255,255,.7);
		    // add the mask.
		    // aplicar máscara.
		    mask: url(#mask);
		  }
		}
		.check{
			display:flex;
			align-items: center;

			input{
				margin-bottom:0;
				min-width:50px;
				background:transparent;
			}
			label{
				padding:0;
			}
		}
		button{
			margin-bottom:0;
			padding: 1rem 1rem;
			@include breakpoint(tiny, max){
				max-width: calc(100% - 18px);
			}
			@include breakpoint(small){
				padding: 2rem;
			}
		}
	}
}
body.productsEnUsOurBrands {
	.brands {
		li{
			flex-direction: column;
			align-items: center;
			background: transparent;
			text-align: center;
			border: 1px solid rgba($border, 0.6);
			padding: 18px 30px;
			@include breakpoint(tiny, max){
				width: 100%;
			}
			@include breakpoint(tiny){
				padding: 18px;
			}
			@include breakpoint(huge){
				padding: 18px 66px;
			}
			img{
				width: auto;
			}
			.link{
				padding: 0 !important;
				a{
					padding: 10px 0 !important;
				}
			}
			.name{
				text-align: center;
				padding: 10px 0 !important;
			}
		}
	}
}

.WidgetBanner a.openReviewslink{
    color:#313131 !important;
}
.WidgetRatedOnText,.reviewBusinessData,.additionalRevieData{
    color:#313131 !important;
}
.WidgetFooter a.moreReviews, .WidgetFooter a.moreReviews:hover{
    color:#313131 !important;
    border:1px solid #313131 !important;
    background-color: #ffffff !important;
}


body.contact{
	.mobileDistance{
		
	}
}
.workHoursCont{
	@include breakpoint(small) {
		display: flex;
		justify-content: space-between;
	}
	@include breakpoint(medium) {
		justify-content: flex-start;
	}
	@include breakpoint(huge) {
		justify-content: space-around;
	}
	@include breakpoint(mega2) {
		padding-right: rem(100px);
	}
	.bHours{
		//smargin-right: rem(100px);
		@include breakpoint(medium) {
			margin-right: rem(150px);
		}
		@include breakpoint(giant) {
			margin-right: rem(200px);
		}
		@include breakpoint(huge) {
			margin-right: 0;
		}
	}
	.hclosures{
		margin-top: rem(50px);
		@include breakpoint(small) {
			margin-top: 0;
		}
		span{
			display: block;
		}
	}
}
